import React, { Component } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import {language} from "../../constants/language";

class FunFactCOunter extends Component {
    state = {
        didViewCountUp: false
    };

    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({ didViewCountUp: true });
        }
    };

    render() {
        let lan = localStorage.getItem('language') ? localStorage.getItem('language') :'SIN'  ;
        return (
            <React.Fragment>
          <section className="funfacts-area">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="funFact">
                                    <i className="icofont-teacher" />
                                    <h3>
                                        <VisibilitySensor
                                            onChange={this.onVisibilityChange}
                                            offset={{
                                                top: 10
                                            }}
                                            delayedCall
                                        >
                                            <CountUp
                                                start={0}
                                                end={
                                                    this.state.didViewCountUp
                                                        ? 30
                                                        : 0
                                                }
                                                //suffix="K"
                                                duration={2}
                                            />
                                        </VisibilitySensor>
                                        +
                                    </h3>
                                    <p>{language[lan].teachers}</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="funFact">
                                    <i className="icofont-group-students" />
                                    <h3>
                                        <VisibilitySensor
                                            onChange={this.onVisibilityChange}
                                            offset={{
                                                top: 10
                                            }}
                                            delayedCall
                                        >
                                            <CountUp
                                                start={0}
                                                end={
                                                    this.state.didViewCountUp
                                                        ? 25000
                                                        : 0
                                                }
                                                // suffix="k"
                                                duration={2}
                                            />
                                        </VisibilitySensor>
                                        +
                                    </h3>
                                    <p>{language[lan].students}</p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="funFact">
                                    <i className="icofont-cubes" />
                                    <h3>
                                        <VisibilitySensor
                                            onChange={this.onVisibilityChange}
                                            offset={{
                                                top: 10
                                            }}
                                            delayedCall
                                        >
                                            <CountUp
                                                start={0}
                                                end={
                                                    this.state.didViewCountUp
                                                        ? 150
                                                        : 0
                                                }
                                                //suffix="K"
                                                duration={2}
                                            />
                                        </VisibilitySensor>
                                        +
                                    </h3>
                                    <p>{language[lan].classes}</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="funFact">
                                    <i className="icofont-presentation-alt" />
                                    <h3>
                                        <VisibilitySensor
                                            onChange={this.onVisibilityChange}
                                            offset={{
                                                top: 10
                                            }}
                                            delayedCall
                                        >
                                            <CountUp
                                                // decimals={2}
                                                start={0}
                                                end={
                                                    this.state.didViewCountUp
                                                        ? 1000
                                                        : 0
                                                }
                                                duration={2}
                                            />
                                        </VisibilitySensor>
                                        +
                                    </h3>
                                    <p>{language[lan].sessions}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default FunFactCOunter;
