import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LapImg from '../../../assets/img/New/lap2.webp'
import './WorkFlow.scss'
import {lang, language} from "../../../constants/language";


class WorkFlow extends React.Component {
    render() {
        let lan = localStorage.getItem('language') ? localStorage.getItem('language') :'SIN'  ;
        return (
            <div>
                <Container className='workFlow'>
                    <Row>
                        <Col sm={12} md={4} lg={4}>
                            <h2 className='topicWorkFlow'>
                                {language[lan].OurServices}
                                <hr/>
                            </h2>
                          <ul className='services'>
                              <li> {language[lan].serviceOne}</li>
                              <li> {language[lan].serviceTwo}</li>
                              <li> {language[lan].serviceThree}</li>
                              <li> {language[lan].serviceFour}</li>
                              <li> {language[lan].serviceFive}</li>
                              <li> {language[lan].serviceSix}</li>
                              <li> {language[lan].serviceSeven}</li>
                          </ul>
                        </Col>


                        <Col sm={12} md={4} lg={4} style={{marginTop: '5%'}}>
                            <img src={LapImg} width='100%' alt=""/>
                        </Col>
                        <Col sm={12} md={4} lg={4} style={{marginTop: '5%'}}>
                            <h4 style={{marginBottom: '5%'}}>
                                {language[lan].howItsWorkTopic}
                            </h4>
                            <hr/>
                            <p style={{color: 'black', marginBottom: '15px'}}><strong>
                                {language[lan].howItsWorkSubTopic01}
                            </strong>
                                <br/>{language[lan].howItsWorkSubPara01} </p>

                            <p style={{color: 'black', marginBottom: '15px'}}><strong>{language[lan].howItsWorkSubTopic02}</strong>
                                <br/>{language[lan].howItsWorkSubPara02} </p>
                            <p style={{color: 'black', marginBottom: '15px'}}><strong>{language[lan].howItsWorkSubTopic03}</strong>
                                <br/> {language[lan].howItsWorkSubPara03} </p>
                            <p style={{color: 'black', marginBottom: '15px'}}><strong>{language[lan].howItsWorkSubTopic04}</strong>
                            </p>


                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default WorkFlow;