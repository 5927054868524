import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import './Landing.scss'
import {LinkContainer} from "react-router-bootstrap";
import {Link} from "react-scroll";
import video from '../../../assets/video/Intro_Video_Landing.webm'
import {language} from "../../../constants/language";
import ModalVideo from "react-modal-video";


class landingFirstPage extends React.Component {

    state = {
        languageType: '',
        isOpen: false,
        isSafari: false
    };

    openModal = () => {
        this.setState({isOpen: true})
    };

    async componentDidMount() {
        let video = document.getElementById("BgVideo");
        video.muted = true;
        video.play();

        // Opera 8.0+
        let isOpera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
        // Firefox 1.0+
        let isFirefox = typeof InstallTrigger !== 'undefined';   // Firefox 1.0+
        let isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
        // At least Safari 3+: "[object HTMLElementConstructor]"
        let isChrome = !!window.chrome && !isOpera;              // Chrome 1+
        let isIE = /*@cc_on!@*/false || !!document.documentMode;   // At least IE6
        console.log(isChrome, isFirefox, isIE, isOpera, isSafari, isIE);

        if (isSafari) {
            await this.setState({isSafari: true})
        }
    }

    render() {
        let lan = localStorage.getItem('language') ? localStorage.getItem('language') : 'SIN';
        return (
            <div id="home" className={this.state.isSafari ?'isSafariLoad':'landing'}>
                <ModalVideo
                    channel="youtube"
                    className="intro-video"
                    isOpen={this.state.isOpen}
                    videoId="TzBL1Z8g97A"
                    onClose={() =>
                        this.setState({
                            isOpen: false
                        })
                    }
                />
                {this.state.isSafari ? null :
                    <video id="BgVideo" autoPlay muted loop playsinline className='testBackgroundVideo'>
                        <source src={video} type="video/mp4"/>
                    </video>
                }
                <Container className='firstView'>
                    <Row>
                        <Col sm={12} md={8} lg={8}>
                            <h1 style={{fontSize: '45px', color: 'white'}}>{language[lan].Welcome}</h1>
                            <h2 style={{color: 'white'}}>{language[lan].WelcomeSub}</h2>

                            <p className='welcomePara' style={{color: 'white',whiteSpace: 'pre-line'}}>{language[lan].WelcomePara}</p>
                            <div className='btnSection'>
                                <LinkContainer className='btnLanding' to={`/inquire`}>
                                    <Link
                                        activeClass="active"
                                        path={`/inquire`}
                                    >
                                        <p className="btn btn-primary">
                                            Let's Get Started
                                        </p>
                                    </Link>
                                </LinkContainer>

                                <button
                                    onClick={this.openModal}
                                    className="btn btn-secondary btnLanding"
                                >
                                    <i className="icofont-ui-play"/>
                                    Watch Our Video
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Container>


            </div>
        )
    }

}

export default landingFirstPage;