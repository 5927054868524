import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import {LinkContainer} from "react-router-bootstrap";
import {Link} from "react-scroll";
import {Navbar, Container, Nav} from "react-bootstrap";
import logo from '../../assets/img/logo-dark.png';
import Dropdown from "react-bootstrap/Dropdown";
import {Form, Modal} from "react-bootstrap";
import {language} from "../../constants/language";
import '../../assets/fonts/Iskoola Pota.ttf'

class NavBar extends Component {

    state = {
        selectLanguage: localStorage.getItem('language') ? localStorage.getItem('language') :'SIN' ,
    };

    componentDidMount() {
        localStorage.setItem('lang',this.state.selectLanguage);

        let elem = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elem.classList.add("is-sticky");
            } else {
                elem.classList.remove("is-sticky");
            }
        });
        let scrollWithOffset = (el, offset) => {
            const elementPosition = el.offsetTop - offset;
            window.scroll({
                top: elementPosition,
                left: 0,
                behavior: "smooth"
            });
        };
        this.setState({scrollWithOffset});
    }

    closeNavbar() {
        if (window.matchMedia("screen and (max-width: 991px)").matches) {
            document.getElementById("collaspe-btn").click();
        }
    }

    changeStatusLanguage = async (event) => {
        let lang= event.target.value;
          localStorage.setItem('language',lang);
          this.setState({selectLanguage:event.target.value});
        this.props.selectLang(event.target.value);
    };

    render() {
        let lan = localStorage.getItem('language') ? localStorage.getItem('language') :'SIN'  ;
        return (
            <React.Fragment styke={{position:'sticky'}}>
                <Navbar
                    sticky="top"
                    id="navbar"
                    bg="light"
                    expand="lg"
                    className="navbar navbar-expand-xm navbar-light bg-light"
                    collapseOnSelect={true}
                >
                    <Container>
                        {this.props.pageName === "home" ? (
                            <React.Fragment>
                                <Link
                                    activeClass="active"
                                    to="home"
                                    spy={true}
                                    smooth={true}
                                    offset={-200}
                                    duration={800}
                                    className="navbar-brand"
                                >
                                    <img style={{maxWidth: "30%"}} src={logo} alt="log"/>
                                </Link>
                            </React.Fragment>
                        ) : (
                            <LinkContainer exact to="/">
                                <Navbar.Brand className="navbar-brand">
                                    <img style={{maxWidth: "30%"}} src={logo} alt="log"/>

                                </Navbar.Brand>
                            </LinkContainer>
                        )}

                        <Navbar.Toggle
                            aria-controls="basic-navbar-nav"
                            id="collaspe-btn"
                        />

                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ml-auto">
                                {this.props.pageName === "home" ? (
                                    <React.Fragment>
                                        <Link
                                            activeClass="active"
                                            to="home"
                                            spy={true}
                                            smooth={true}
                                            offset={-200}
                                            duration={800}
                                            className="nav-link homenav"
                                        >
                                            {language[lan].home}
                                        </Link>

                                        <Link
                                            activeClass="active"
                                            to="features"
                                            spy={true}
                                            smooth={true}
                                            offset={-70}
                                            duration={800}
                                            className="nav-link"
                                            onClick={this.closeNavbar}
                                        >
                                            {language[lan].Features}
                                        </Link>

                                        {/* <Link
                                            activeClass="active"
                                            to="about"
                                            spy={true}
                                            smooth={true}
                                            offset={-70}
                                            duration={800}
                                            className="nav-link"
                                            onClick={this.closeNavbar}
                                        >
                                            About
                                        </Link> */}

                                        {/* <Link
                                            activeClass="active"
                                            to="services"
                                            spy={true}
                                            smooth={true}
                                            offset={-70}
                                            duration={800}
                                            className="nav-link"
                                            onClick={this.closeNavbar}
                                        >
                                            Services
                                        </Link> */}
                                        {/*
                                        <Link
                                            activeClass="active"
                                            to="team"
                                            spy={true}
                                            smooth={true}
                                            offset={-70}
                                            duration={800}
                                            className="nav-link"
                                            onClick={this.closeNavbar}
                                        >
                                            Team
                                        </Link> */}
                                        {/* <Link
                                            activeClass="active"
                                            to="faq"
                                            spy={true}
                                            smooth={true}
                                            offset={-70}
                                            duration={800}
                                            className="nav-link"
                                            onClick={this.closeNavbar}
                                        >
                                            FAQ
                                        </Link> */}
                                        {/*
                                        <Link
                                            activeClass="active"
                                            to="pricing"
                                            spy={true}
                                            smooth={true}
                                            offset={-70}
                                            duration={800}
                                            className="nav-link"
                                            onClick={this.closeNavbar}
                                        >
                                            Pricing
                                        </Link> */}
                                        <Link
                                            activeClass="active"
                                            to="contact"
                                            spy={true}
                                            smooth={true}
                                            offset={-70}
                                            duration={800}

                                            className="nav-link"
                                            onClick={this.closeNavbar}
                                        >
                                            <i className="icofont-phone"></i> (+94) 117 112 119
                                        </Link>


                                    </React.Fragment>
                                ) : (
                                    //Contact or Other Page Navbar Start
                                    <React.Fragment>
                                        <NavLink
                                            className="nav-link"
                                            to="/"
                                            activeClassName=""
                                        >
                                            {language[lan].home}
                                        </NavLink>

                                        {/*<NavLink
                                                className="nav-link"
                                                to="/features"
                                                activeClassName=""
                                            >
                                                Features
                                        </NavLink>
                                            <NavLink
                                                className="nav-link"
                                                to="/"
                                                activeClassName=""
                                            >
                                                About
                                        </NavLink>
                                            <NavLink
                                                className="nav-link"
                                                to="/"
                                                activeClassName=""
                                            >
                                                Services
                                        </NavLink>
                                            <NavLink
                                                className="nav-link"
                                                to="/"
                                                activeClassName=""
                                            >
                                                Team
                                        </NavLink>
                                            <NavLink
                                                className="nav-link"
                                                to="/"
                                                activeClassName=""
                                            >
                                                FAQ
                                        </NavLink>
                                            <NavLink
                                                className="nav-link"
                                                to="/"
                                                activeClassName=""
                                            >
                                                Pricing
                                        </NavLink>
                                        <NavLink
                                                className="nav-link"
                                                to=""
                                                activeClassName=""
                                            >
                                                Contact
                                        </NavLink> */}
                                        <Link
                                            activeClass="active"
                                            // to="contact"
                                            spy={true}
                                            smooth={true}
                                            offset={-70}
                                            duration={800}

                                            className="nav-link"
                                            onClick={this.closeNavbar}
                                        >
                                            <i className="icofont-phone"></i> (+94) 117 112 119
                                        </Link>

                                    </React.Fragment>
                                    //Contact or Other Page Navbar End
                                )}

                                {this.props.pageName === "home" &&
                                <LinkContainer to={`/inquire`}>
                                    <Link
                                        activeClass="active"
                                        // path={`${process.env.PUBLIC_URL}/inquire`}
                                        to={`/inquire`}
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={800}
                                        className="btn btn-primary"
                                        onClick={this.closeNavbar}
                                    >
                                        <p className="nav-btn">{language[lan].Inquire_Now}</p>
                                    </Link>
                                </LinkContainer>
                                }

                                <Link style={{marginLeft: '5px'}}>
                                    <Form.Control
                                        style={{marginLeft: '1%',height:'50px'}}
                                        as="select"
                                        name="selectStatus"
                                        onChange={this.changeStatusLanguage}
                                        value={this.state.selectLanguage}
                                    >
                                        <option style={{margin:'20px'}} value={'ENG'}>English</option>
                                        <option style={{padding:'20px'}} value={'SIN'}>සිංහල </option>
                                    </Form.Control>
                                </Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </React.Fragment>
        );
    }
}

export default NavBar;
