// import React from "react";

import React from "react";

/**
 * Created by WebStorm.
 * User: athukorala
 * Date: 10/16/20
 * Time: 9:18 AM
 */
export const lang = "SIN";

export const language = {
    ENG: {
        Welcome: "Welcome to the “Edulab”",
        WelcomeSub: " online teaching platform.",
        WelcomePara: "Are you a Teacher / Institute owner? \n  " +
            "We are here to build a unique website for you or start your class via our own web platform. ",
        OurServices: 'Services which we are provide',

        //NavBar
        home:'Home',
        Features:'Features',
        Inquire_Now:'Inquire Now',


        //countRoller
        teachers:'Teachers',
        classes:'Classes',
        students:'Students',
        sessions:'Sessions',

        //service list
        serviceOne: 'Learning management system',
        serviceTwo: 'Online and pre-recorded video classes',
        serviceThree: 'Course management',
        serviceFour: 'Online MCQ and essay examination',
        serviceFive: 'Payment management via payment gateway and deposit slip',
        serviceSix: 'SMS gateway integration',
        serviceSeven: 'Finance management',

        //keyFeatures
        KeyFeatures: 'Key Features',
        OnlinePayment: 'Online Payment',
        onlineDesc: ' Students can purchase their monthly payments via debit or credit card. No more hessle for collecting class fees.',

        NoCompetition: 'No Competition',
        NoCompetitionDesc: '  Unique URLs will promote only your teachers and classes to your student base.',

        FreeDomain: 'Free Domain',
        FreeDomainDesc: ' You get a unique subdomain to your institute and are able to promote your classes to your student base. ',

        NoNeedOfPhysicalLocation: 'No need of Physical Location',
        NoNeedOfPhysicalLocationDesc: 'Teachers and students are able to meet via video call without going to a physical location. ',


        //ourAdvantages
        ourAdvantagesOne: 'Use your unique institute link to reach to your student base',
        ourAdvantagesOneDesc: ' You can promote your unique institute link to reach your students and conduct online classes.',
        ourAdvantagesOneSubTopic: 'Our Latest Websites',

        ourAdvantagesTwo: ' Enable online payment option to your students ',
        ourAdvantagesTwoDesc: 'Through the Edulab platform students can easily pay their tuition fee online. We allow payments through Visa & Mastercard.',
        ourAdvantagesTwoSubTopic: 'Ways You can make Payments',
        ourAdvantagesTwoList01: 'Deposit Slip',
        ourAdvantagesTwoList02: 'Payment by Card ',

        topicResponsive: 'Attractive and User Friendly Interface',
        responsiveSite: 'Edulab platform gives uniqueness for the teachers by providing a unique outlook and user friendly interface with easy user journey for the student. Students are able to pay their tuition fee easily with a few clicks and join the class easily as well. Most importantly, Edulab platform is fully mobile responsive which supports any mobile device.',

        howItsWorkTopic:'  How it Works',
        howItsWorkSubTopic01:' 1.Register to Edulab platform',
        howItsWorkSubPara01:' Register and contact edulab team for create own web portal',

        howItsWorkSubTopic02:'2.Update teacher profile',
        howItsWorkSubPara02:'  Add your teachers to the website with their details.',

        howItsWorkSubTopic03:' 3.Create Classes',
        howItsWorkSubPara03:' Add class time table to the each teacher in the institute .',

        howItsWorkSubTopic04:'4.Share the unique url with students',

        // Have Some Questions ?
        HaveSomeQuestions: 'Have Some Questions ?',
        HaveSomeQuestionsDesc: 'Whether you have a question about features, pricing, need a demo or anything else, our team is ready to answer all of your questions.',
        //form
        Name: 'Name',
        Email: 'Email Address',
        Subject: 'Subject',
        TelephoneNumber: 'Telephone Number',
        YourMessage: 'Your message',
        Submit: 'Submit',
        //footerDesc
        aboutEdulabDesc: 'Edulab is an authentic online teaching platform which connects Sri Lankan private teachers and students.',
        UsefulLinks: 'UsefulLinks',
        ContactInfo: 'Contact Info',
        Address: '17A , Hirana Road, Panadura',
        copyRight: 'Copyright © 2020 All Rights Reserved.',


        InquiryForm:'Inquiry Form',
        subTopicInquiryForm:'Tell us more about your',
        lastTopicInquiryForm:'To register with edulab please take moment and fill up this inquiry form ',
        FullName:'Full Name',
        LastName:'Last Name',
        PhoneNumber:'Phone / Fax',
        Institute_BusinessName:'Institute/Business Name',
        PreferredLink:'Preferred Link',
        YourEdulabLink:'Your Edulab Link -https://',
        AnythingMsg:'Anything you\'d like to know?',
        SubmitInquiry:'Submit Inquiry'


    },

    SIN: {
        Welcome: "“Edulab” වෙත සාදරයෙන් පිළිගනිමු.",
        WelcomeSub: "  ",
        WelcomePara:'ඔබ ගුරුවරයෙක්ද / අධ්‍යාපන ආයතනයක හිමිකරුවෙක්ද?\n' +
            'ඔබටම අනන්‍ය වූ වෙබ් අඩවිය අප හරහා නිර්මාණය කරගන්න.\n' +
            'නැතහොත් අපගේ අධ්‍යාපන ආයතනය හරහා ඔබගේ ඉගැන්වීම් කටයුතු කරන්න.',

        //NavBar
        home:'මුල් පිටුව',
        Features:'විශේෂාංග',
        Inquire_Now:'විමසීම්',

        OurServices: 'අප විසින් ඔබ වෙත සපයන සේවාවන්',


        //countRoller
        teachers:'ගුරුවරුන්',
        classes:'පන්ති',
        students:'සිසුන්',
        sessions:'සැසිවාර',

        //service list
        serviceOne: 'ඉගෙනුම් කළමනාකරණ පද්ධතිය',
        serviceTwo: 'මාර්ගගත සහ පෙර පටිගත කළ වීඩියෝ පන්ති',
        serviceThree: 'පාඨමාලා කළමනාකරණය',
        serviceFour: 'මාර්ගගත MCQ සහ රචනා විභාගය',
        serviceFive: 'ගෙවීම් ගේට්වේ සහ තැන්පතු ස්ලිප් හරහා ගෙවීම් කළමනාකරණය',
        serviceSix: 'සිසුන්ට පන්ති පිළිබද කෙටි පණිවුඩ යැවීමේ හැකියාව',
        serviceSeven: 'මුදල් කළමනාකරණය',

        KeyFeatures: 'මුලික ලක්ෂණ',
        OnlinePayment: 'මාර්ගගත ගෙවීම්/ Online ගෙවීම්',
        onlineDesc: ' සිසුන්ට Online, ක්‍රෙඩිට් හෝ ඩෙබිට්  කාඩ්පත් මගින් පන්ති ගාස්තු ගෙවීමේ හැකියාව. එබැවින් ඔබට තවදුරටත් පන්ති ගාස්තු එකතු කිරීමට අනවශ්‍ය ලෙස කරදර වීමට අවශ්‍ය නැත.',

        NoCompetition: 'තරගකාරිත්වයෙන් තොර වීම',
        NoCompetitionDesc: 'ඔබටම අනන්‍යවූ URL / ලින්ක් එකක් මගින් ඔබ ආයතනයේ ගුරුවරු සහ පන්ති පිලිබදව පමණක් ඔබගේ ශිෂ්‍ය ප්‍රජාව දැනුවත් කිරීමට ඇති හැකියාව',

        FreeDomain: 'Domain නොමිලේ',
        FreeDomainDesc: ' ඔබට අනන්‍ය Subdomain එකක් ඔබ අයහනයට ලබාගෙන ඒ හරහා ඔබගේ පන්ති පිලිබදව ඔබගේ ශිෂ්‍ය ප්‍රජාවට දැනුවත් කිරීම් කිරීමට ඇති හැකියාව.',

        NoNeedOfPhysicalLocation: 'භෞතික පිහිටීම අවශ්‍ය නොවේ /ගොඩනැගිලි අනවශ්‍යයි',
        NoNeedOfPhysicalLocationDesc: 'ගුරුවරුන්ට හා සිසුන්ට video ඇමතුම් හරහා සම්බන්ද වී පන්ති පවත්වා ගෙන යාමට හැකිය. අනවශ්‍ය ලෙස ගොඩනැගිලි වලට මුදල් ගෙවීමට උවමනා නැත.',


        //ourAdvantages
        ourAdvantagesOne: ' ඔබගේ සිසුන් වෙත ළඟාවීමට ඔබ ආයතනයට අනන්‍ය වූ ලින්ක් එකක් භාවිතා කරන්න',
        ourAdvantagesOneDesc: '  ඔබ ආයතනයේ ප්‍රවර්ධන කටයුතු සඳහා මෙන්ම සිසුන් වෙත ළගා වීමට හා online පන්ති පැවැත්වීමට අප විසින් සපයනු ලබන ඔබට අනන්‍ය වූ ලින්ක් එක භාවිතා කල හැකිය.',
        ourAdvantagesOneSubTopic: ' අපගේ නවතම වෙබ් අඩවි',

        ourAdvantagesTwo: ' ඔබගේ සිසුන්ට Online ක්‍රමයට මුදල් ගෙවීමට අවස්ථාව ලබා දෙන්න.',
        ourAdvantagesTwoDesc: ' Edulab හරහා සිසුන්ට ඔවුන්ගේ පන්ති ගාස්තු Online ගෙවීමට අවස්ථාව ලබා දෙමින් Visa හා Mastercard මඟින් ගෙවීම් සිදු කිරීමට හැකිය.',
        ourAdvantagesTwoSubTopic: 'ගෙවීම් සිදු කල හැකි ආකාර.',
        ourAdvantagesTwoList01: ' බැංකු  තැන්පතු රිසිට්පත්.',
        ourAdvantagesTwoList02: 'කාඩ් මඟින් සිදු කරනු ලබන ගෙවීම්.',

        topicResponsive:'ආකර්ශනීය හා භාවිතයට පහසු වෙන පරිදි සැදූ වෙබ් අඩවි',
        responsiveSite: 'EduLab, ගුරුවරුන්ට සුවිශේෂී බවක් ලබා දෙන අතර එමඟින් ශිෂ්‍යයාට පහසුවෙන් හැසිරවිය හැකි සිත්ගන්නා සුළු platform එකක් සපයා දේ. Click කිරීම් කිහිපයකින් සිසුන්ට උපකාරක පන්ති ගාස්තුව පහසුවෙන් ගෙවා පන්තියට පහසුවෙන් සම්බන්ධ විය හැකිය. වැදගත්ම දෙය නම්, ඕනෑම ජංගම උපාංගයකට සහය දක්වන EduLab Platform එක  සම්පූර්ණයෙන්ම mobile responsive වීමයි.',


        howItsWorkTopic:'Edulab ක්‍රියාත්මක වන ආකාරය',
        howItsWorkSubTopic01:'1. Edulab platform එකට ලියාපදිංචි වන්න',
        howItsWorkSubPara01:'තමන්ගේම වෙබ් අඩවිය සෑදීම සඳහා ලියාපදිංචි වී Edulab කණ්ඩායම හා සම්බන්ධ වන්න.',

        howItsWorkSubTopic02:'2. ගුරුවරයාගේ තොරතුරු වෙබ් අඩවියට ඇතුලත් කරන්න ',
        howItsWorkSubPara02:'ඔබේ ගුරුවරුන්ගේ විස්තර සමඟ වෙබ් අඩවියට එක් කරන්න.',

        howItsWorkSubTopic03:'3. පන්ති සාදන්න',
        howItsWorkSubPara03:'ආයතනයේ එක් එක් ගුරුවරයාට පන්ති කාලසටහන එක් කරන්න.',

        howItsWorkSubTopic04:'4. ඔබේ ආයතනයට අදාල URL LINK එක සිසුන් සමඟ බෙදා ගන්න.',


        // Have Some Questions ?
        HaveSomeQuestions: 'ඔබගේ ගැටලුව යොමුකරන්න',
        HaveSomeQuestionsDesc: 'ඔබට විශේෂාංග, මිලකරණය, නිරූපණයක් හෝ වෙනත් යමක් ගැන ප්‍රශ්නයක් වේ නම් , ඔබගේ සියලු ප්‍රශ්නවලට පිළිතුරු දීමට අපගේ කණ්ඩායම සූදානම්.',
        //form
        Name: 'නම',
        Email: 'ඊමේල් ලිපිනය',
        Subject: 'විෂය',
        TelephoneNumber: 'දුරකථන අංකය',
        YourMessage: 'ඔබේ පණිවිඩය/ ගැටලුව',
        Submit: 'යොමුකරන්න ',
        //footerDesc
        aboutEdulabDesc: 'EduLab යනු ශ්‍රී ලංකාවේ පෞද්ගලික ගුරුවරුන් හා සිසුන් සම්බන්ධ කරන නිසි ප්‍රමිතියකින් හා වගකීමකින් යුත්  Online ඉගැන්වීම් වේදිකාවකි.',
        UsefulLinks: 'ප්‍රයෝජනවත් ලින්ක්',
        ContactInfo: 'විමසන්න ',
        Address: '17A , හිරණ පාර, පානදුර',
        copyRight: 'ප්‍රකාශන හිමිකම © 2020 සියලුම හිමිකම් ඇවිරිණි.',



        InquiryForm:'තොරතුරු පත්‍රය',
        subTopicInquiryForm:'ඔබ පිළිබදව වැඩි විස්තර සපයන්න. ',
        lastTopicInquiryForm:'Edulab සමඟ ලියාපදිංචි වීමට කරුණාකර මොහොතක් වෙන් කරගෙන මෙම විමසීම් පත්‍රිකාව පුරවන්න.',
        FullName:'සම්පූර්ණ නම',
        LastName:'අවසන් නම',
        PhoneNumber:'දුරකතන අංකය',
        Institute_BusinessName:'ආයතනය/ව්‍යාපාර නාමය',
        PreferredLink:'භාවිත කිරීමට බලාපොරොත්තු වන Link එක',
        YourEdulabLink:'ඔබේ Edulab Link එක -https://',
        AnythingMsg:'වැඩි දුර දැනගැනීමට අවශ්‍ය වන විස්තර',
        SubmitInquiry:'තොරතුරු පත්‍රය ඉදිරිපත් කරන්න '


    },
};

