import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import imgSet from "../../../assets/img/New/set View.webp";
import './VersionView.scss'
import {lang, language} from "../../../constants/language";


class VersionView extends React.Component {
    render() {
        let lan = localStorage.getItem('language') ? localStorage.getItem('language') :'SIN'  ;
        return (
            <div>
                <Container className="versionView">
                    <Row className="UIView">
                        <Col sm={12} md={12} lg={12}>
                            <h2 className=' section-title UITopic ' style={{textAlign: 'center'}}>  {language[lan].topicResponsive}</h2>
                            <img src={imgSet} width="100%" alt="img"/>
                            <p style={{textAlign: 'center'}}>{language[lan].responsiveSite} </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default VersionView;