import React, {Component} from "react";
import i from "react-icofont";
import PropTypes from "prop-types";
import MobilewithSir from '../../../assets/img/New/Sir & With2.png'
import './about.scss'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {language} from "../../../constants/language";

class About extends Component {

    render() {
        let lan = localStorage.getItem('language') ? localStorage.getItem('language') :'SIN'  ;
        const aboutData = this.props.aboutsData.map((about, index) => (
            <React.Fragment key={index}>
                {index % 10 === 0 ? (
                    <div className="row mt-100 aboutCardGrid" style={{backgroundColor: '#ffffff'}}>
                        <div className="col-lg-6 col-md-6 imgCard">
                            <div className="img">
                                <img src={MobilewithSir} alt="img"/>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1"></div>
                        <div className="col-lg-5 col-md-5 contentCard"
                           >
                            <div className="about-text mb-0">
                                <h1>{about.position}</h1>
                                <h3>{language[lan].ourAdvantagesOne}</h3>
                                <p>{language[lan].ourAdvantagesOneDesc}</p>
                                <Row>
                                    <Col className='resourceLinks' sm={11} mg={12} lg={12} >
                                        <hr/>
                                        <h6>{language[lan].ourAdvantagesOneSubTopic}</h6>
                                        <hr/>
                                    </Col>
                                    <Col sm={11} mg={6} lg={6} >
                                        <a href="https://tamillanguageacademy.lk/"  target="_blank" className='btn btn-secondary  btnRecourse' >
                                            <a style={{textDecoration:'none',color:'white'}} href="https://learn.tamillanguageacademy.lk" target="_blank">
                                                Tamil Academy
                                            </a>
                                        </a>
                                    </Col>
                                    <Col sm={11} mg={6} lg={6}>
                                        <a className='btn btn-primary btnRecourse'  href="https://chemistryfirst.lk"  target="_blank">
                                            <a style={{textDecoration:'none',color:'white'}} href="https://learn.chemistryfirst.lk/home/teacher" target="_blank">
                                               Chemistry First
                                            </a>
                                        </a>
                                    </Col>
                                </Row>

                            </div>

                        </div>

                    </div>
                ) : (
                    <div className="row mt-100 aboutCardGrid">
                        <div className="col-lg-5 col-md-5 contentCard">

                            <div className="about-text mb-0">
                                <h1>{about.position}</h1>
                                <h3>{language[lan].ourAdvantagesTwo}</h3>
                                <p>{language[lan].ourAdvantagesTwoDesc}</p>

                                <Row>

                                    <Col sm={11} mg={12} lg={12} className='resourceLinks'>
                                        <hr/>
                                        <h6>{language[lan].ourAdvantagesTwoSubTopic}</h6>
                                        <hr/>
                                    </Col>
                                    <Col sm={11} mg={6} lg={6} >
                                           <li>{language[lan].ourAdvantagesTwoList01}</li>
                                    </Col>
                                    <Col sm={11} mg={6} lg={6}>
                                        <li>{language[lan].ourAdvantagesTwoList02}</li>
                                    </Col>
                                </Row>

                            </div>

                        </div>
                        <div className="col-lg-1 col-md-1"></div>
                        <div className="col-lg-6 col-md-6 imgCard">
                            <div className="img">
                                <img src={about.image} alt="img"/>
                            </div>
                        </div>
                    </div>
                )}
            </React.Fragment>
        ));
        return (
            <React.Fragment>
                {/* Start About Area */}
                <section id="about" className="about-area ">
                    <div className="container">
                        {aboutData}
                    </div>
                </section>
                {/* End About Area */}
            </React.Fragment>
        );
    }
}

//Props Types
About.propTypes = {

    aboutBtnText: PropTypes.string,
    aboutBtnLink: PropTypes.string,
    aboutsData: PropTypes.array
};

//Default Props
About.defaultProps = {


    aboutBtnLink: "#0",
    aboutsData: [
        {
            position: "01",
            title: "Use your unique institute link to reach to your student base ",
            description:
                "You can promote your unique institute link to reach your students and conduct online classes.",

        },
        {
            image: require("../../../assets/img/New/Paymnet.png"),
            position: "02",
            title: "Enable online payment option to your students ",
            description:
                " Through Edulab platform students can easily pay their tuition fee online. We allow payments through Visa & Mastercard.",

        }

    ]
};

export default About;
