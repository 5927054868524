import React, {Component} from "react";

import NavBar from "../components/common/NavBar";
import Showcase from "../components/versionTwo/Showcase";
import About from "../components/versionTwo/About/About";
import Features from "../components/versionTwo/Features";
import FunFactCounter from "../components/versionTwo/FunFactCounter";
import Works from "../components/common/Works";
import ContactUS from "../components/common/ContactUS";
import ContactForm from './ContactForm';
import Footer from "../components/common/Footer";
import Benifits from "../components/common/Benifits";
import LandingPage from "../components/versionTwo/landingFirstPage/landingFirstPage";
import LatestProject from "../components/versionTwo/LatestProject/LatestProject";
import WorkFlow from "../components/versionTwo/HowItWork/WorkFlow";
import VersionView from "../components/versionTwo/VersionView/VersionView";
import {Language} from "../constants/Constats";
import '../assets/fonts/Iskoola Pota.ttf'

class HomeTwo extends Component {

    state = {
        lang: ''
    };


    componentDidMount() {
        window.scrollTo(0, 0);
        let l = localStorage.getItem('language');

    }
    selectValue=(value)=>{
        console.log(value);
        this.setState({lang:value})
    };

    render() {
        return (
            <React.Fragment>
                <NavBar selectLang={this.selectValue} pageName="home"/>
                <LandingPage/>
                <LatestProject/>
                <WorkFlow/>
                {/*<Works/>*/}
                <FunFactCounter/>
                <VersionView/>
                <Features/>
                <About/>
                <ContactUS/>
                <Footer pageName="home"/>
            </React.Fragment>
        );
    }
}
export default HomeTwo;
