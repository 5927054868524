import React, {Component} from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import './Style/works.scss'
class Works extends Component {
    render() {

        return (
            <React.Fragment>
                <section style={{position:'relative'}} className="how-works-area ptb-100">
                    <div className="container">
                        <div className="section-title">
                            <h3>{this.props.sectionTitle}</h3>
                        </div>

                        <Row>
                            <Col sm={12} md={3} lg={3}>
                                <Card className='single-box Card01'>
                                    <span>1</span>
                                    <h3>Register to Edulab platform</h3>
                                    <p style={{color:'black'}}>Register and contact edulab team for create own web portal.</p>
                                </Card>
                            </Col>
                            <Col sm={12} md={3} lg={3}>
                                <Card className='single-box Card02'>
                                    <span>2</span>
                                    <h3>Update teacher profile </h3>
                                    <p style={{color:'black'}}>Add your teachers to the website with their details.</p>
                                </Card>
                            </Col>
                            <Col sm={12} md={3} lg={3}>
                                <Card className='single-box Card03'>
                                    <span>3</span>
                                    <h3>Create classes</h3>
                                    <p style={{color:'black'}}>Add class time table to the each teacher in the institute .</p>
                                </Card>
                            </Col>
                            <Col sm={12} md={3} lg={3}>
                                <Card className='single-box Card04'>
                                    <span>4</span>
                                    <h3 style={{color:'black'}}>Share the unique url with students</h3>
                                    {/*<p>{work.description}</p>*/}
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

//Props Types
Works.propTypes = {
    sectionName: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    worksData: PropTypes.array
};

//Default Props
Works.defaultProps = {
    sectionName: "Works",
    sectionTitle: "How it Works ",
    sectionDescription:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",

    worksData: [
        {
            position: "01.",
            heading: "Register to Edulab platform",
            description:
                " Register and contact edulab team for create own web portal."
        },
        {
            position: "02.",
            heading: "Update teacher profile ",
            description:
                " Add your teachers to the website with their details."
        },
        {
            position: "03.",
            heading: "Create classes",
            description:
                " Add class time table to the each teacher in the institute ."
        },
        {
            position: "04.",
            heading: "Share the unique url with students "

        }
    ]
};

export default Works;
