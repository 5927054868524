import React, { Component } from "react";
import axios from "axios";
import NavBar from "../components/common/NavBar";

import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import PropTypes from "prop-types";
import Footer from "../components/common/Footer";
import contactImg from "../assets/img/contact_form.png"
import swal from "sweetalert";
import * as validator from '../constants/Validator';
import {language} from "../constants/language";

// https://formcarry.com/s/BWGWYOEM0hXF - prod
// https://formcarry.com/s/K93ZYWQ87R - test

class Inquire extends Component {
    state = {
        lang: ''
    };
    constructor(props) {
        super(props);
        this.state = {
            full_name: "",
            last_name: "",
            number: "",
            email: "",
            business_name: "",
            preferred_link: "",
            message: "",
            successMsg: ""
        };
    }

    mobileNumberValidataion = (value) => {
        return validator.mobileRegex.test(value);
    };

    handleForm = () => {
        const obj = {
            "Full_Name":this.state.full_name,
            "Number":this.state.number,
            "Email": this.state.email,
            "Message": this.state.message,
        };

        axios
            .post("https://formcarry.com/s/BWGWYOEM0hXF", obj, {
                headers: { Accept: "application/json" }
            })
            .then(() => {
                // document.getElementById("contactForm").reset();
                // that.setState({
                //     successMsg: "We received your submission"
                // });
                // document.getElementById("contactForm").reset();
                this.messageHandler("SUCCESS")
            })
            .catch(error => { this.messageHandler("FAILED") });
    };

    messageHandler = (status) => {
        swal({
            title: status === "SUCCESS" ? "We received your submission":"Connection interrupted. Please try again!",
            icon: status === "SUCCESS" ? "success":"error",
            closeOnClickOutside: false,
            buttons: {
                dangerMode: {
                    text: "Okay",
                    value: "action",
                    className: "okay-btn"
                }
            }
        })
            .then((value) => {
                switch (value) {
                    case "action":
                        if(status === "SUCCESS"){
                            this.props.history.push("/")
                        }
                        break;
                    default:
                }
            })
    };

    handleFields = e => {
        this.setState({ [e.target.name]: e.target.value, successMsg: '' })
    };

    selectValue=(value)=>{
        this.setState({lang:value})
    };

    render() {
        let lan = localStorage.getItem('language') ? localStorage.getItem('language') :'SIN'  ;
        return (
            <React.Fragment>

                <div>
                    <NavBar selectLang={this.selectValue}  />
                    {/* Start Contact Area */}
                    <section id="inquire" className="contact-area bg-gray  ">
                        <div className="container contact-bg" >

                            <div className="row  ">

                                <div className="col-lg-12 col-md-12 cont ">
                                    <h5 className="titles">
                                        {language[lan].InquiryForm}
                                    </h5>
                                    <div className="section-title ">
                                        <h3>{language[lan].subTopicInquiryForm}</h3>
                                        <p>
                                            {language[lan].lastTopicInquiryForm}
                                        </p>
                                        <p>
                                            {this.props.contactDescriptionsTwo}
                                        </p>
                                    </div>

                                <div className="col-lg-12 col-md-12 contact-img" >
                                <img  src={contactImg} />
                                </div>
                                </div>

                            </div>
                        </div>
                    </section>



                    <section  >
                        <div className="container form-area" >
                            <div className="row ">
                                <div className="col-lg-12 col-md-12">
                                    <ValidationForm
                                        id="contactForm"
                                        onSubmit={(e, formData, inputs) => {
                                            e.preventDefault();
                                            this.handleForm(formData);
                                        }}
                                    >
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group ">
                                                    <label className={"req-lbl"}> {language[lan].FullName}</label>
                                                    <TextInput
                                                        name="full_name"
                                                        id="full_name"
                                                        required
                                                        successMessage=""
                                                        errorMessage="Please enter your First Name"
                                                        className="form-control text-area"
                                                        placeholder={language[lan].FullName}
                                                        autoComplete="off"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                    <div className="help-block with-errors" />
                                                </div>
                                            </div>
                                            {/*<div className="col-lg-12 col-md-12">*/}
                                            {/*    <div className="form-group">*/}
                                            {/*        <label> {language[lan].LastName}</label>*/}
                                            {/*        <TextInput*/}
                                            {/*            name="last_name"*/}
                                            {/*            id="last_name"*/}
                                            {/*            successMessage=""*/}
                                            {/*            errorMessage="Please enter your Last Full Name"*/}
                                            {/*            className="form-control text-area"*/}
                                            {/*            placeholder={language[lan].LastName}*/}
                                            {/*            autoComplete="off"*/}
                                            {/*            onChange={*/}
                                            {/*                this.handleFields*/}
                                            {/*            }*/}
                                            {/*        />*/}
                                            {/*        <div className="help-block with-errors" />*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <label className={"req-lbl"}>{language[lan].PhoneNumber}</label>
                                                    <TextInput
                                                        name="number"
                                                        id="number"
                                                        type={"number"}
                                                        required
                                                        successMessage=""
                                                        validator={this.mobileNumberValidataion}
                                                        errorMessage={{required:"Please enter your Phone Number", validator: "Please enter valid Phone Number"}}
                                                        className="form-control text-area"
                                                        placeholder={language[lan].PhoneNumber+'(+94117112119)'}
                                                        autoComplete="off"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                    <div className="help-block with-errors" />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <label className={"req-lbl"}>{language[lan].Email}</label>
                                                    <TextInput
                                                        name="email"
                                                        id="email"
                                                        type="email"
                                                        required
                                                        successMessage=""
                                                        errorMessage="Please enter your email address"
                                                        className="form-control text-area"
                                                        placeholder={language[lan].Email}
                                                        autoComplete="off"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                    <div className="help-block with-errors" />
                                                </div>
                                            </div>

                                            {/*<div className="col-lg-12 col-md-12">*/}
                                            {/*    <div className="form-group">*/}
                                            {/*        <label>{language[lan].Institute_BusinessName}</label>*/}
                                            {/*        <TextInput*/}
                                            {/*            name="business_name"*/}
                                            {/*            id="business_name"*/}
                                            {/*            type="text"*/}
                                            {/*            //required*/}
                                            {/*            successMessage=""*/}
                                            {/*            errorMessage="Please enter your Institute/Business name"*/}
                                            {/*            className="form-control text-area"*/}
                                            {/*            placeholder={language[lan].Institute_BusinessName}*/}
                                            {/*            autoComplete="off"*/}
                                            {/*            onChange={*/}
                                            {/*                this.handleFields*/}
                                            {/*            }*/}
                                            {/*        />*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {/*<div className="col-lg-12 col-md-12">*/}
                                            {/*    <div className="form-group">*/}
                                            {/*        <label>{language[lan].PreferredLink}</label>*/}
                                            {/*        <TextInput*/}
                                            {/*            name="preferred_link"*/}
                                            {/*            id="preferred_link"*/}
                                            {/*            // required*/}
                                            {/*            successMessage=""*/}
                                            {/*            errorMessage="Please enter your Preferred Link"*/}
                                            {/*            className="form-control text-area"*/}
                                            {/*            placeholder={language[lan].PreferredLink}*/}
                                            {/*            autoComplete="off"*/}
                                            {/*            onChange={*/}
                                            {/*                this.handleFields*/}
                                            {/*            }*/}
                                            {/*        />*/}
                                            {/*        <span>{language[lan].YourEdulabLink}<span className="link-color">{this.state.preferred_link}</span>.edulab.lk/</span>*/}
                                            {/*        <div className="help-block with-errors" />*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <label>{language[lan].AnythingMsg}</label>
                                                    <TextInput
                                                        name="message"
                                                        id="description"
                                                        multiline
                                                        placeholder={language[lan].YourMessage}
                                                        className="form-control text-area"
                                                        //required
                                                        successMessage=""
                                                        errorMessage="Please write your message"
                                                        rows="5"
                                                        autoComplete="off"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <button className="btn btn-primary">
                                                {language[lan].SubmitInquiry}
                                            </button>
                                        </div>
                                        <div className="clearfix" />
                                    </ValidationForm>
                                    {this.state.successMsg !== "" ? (
                                        <h3 className="contactMsg">
                                            {this.state.successMsg}
                                        </h3>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Start Contact Area */}
                </div>
                <Footer pageName="inquire" />
            </React.Fragment >


        );
    }
}

//Props Types
Inquire.propTypes = {
    sectionName: PropTypes.string,

    phoneTitle: PropTypes.string,
    phoneNoOne: PropTypes.string,
    phoneNoTwo: PropTypes.string,
    emailTitle: PropTypes.string,
    emailAddressOne: PropTypes.string,
    emailAddressTwo: PropTypes.string,
    locationTitle: PropTypes.string,
    locationAddressLineOne: PropTypes.string,
    locationAddressLineTwo: PropTypes.string,

    contactTitle: PropTypes.string,
    contactDescriptionsOne: PropTypes.string,
    contactDescriptionsTwo: PropTypes.string
};
//Default Props
Inquire.defaultProps = {
    sectionName: "Inquiry Form",

    phoneTitle: "Phone / Fax",
    phoneNoOne: "+94117112119",
    phoneNoTwo: "+94117112119",
    emailTitle: "E-mail",
    emailAddressOne: "support@edulab.lk",
    emailAddressTwo: "support@edulab.lk",
    locationTitle: "Location",
    locationAddressLineOne: "2750 Quadra Street , Park Area,",
    locationAddressLineTwo: "Victoria, Canada.",

    contactTitle: "Tell us more about your",
    contactDescriptionsOne:
        "To register with edulab please take moment and fill up this inquiry form ",
};

export default Inquire;
