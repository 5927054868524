import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import './LatestProject.scss'
import YouTube from 'react-youtube';

let getYouTubeID1 = require('get-youtube-id');

class LatestProject extends React.Component {

    onReady = (event) => {
        event.target.pauseVideo();
    };


    render() {
        const opts = {
            height: 'auto',
            width: '100%',
            playerVars: {
                autoplay: 0,
            },
        };
        let lan = localStorage.getItem('language') ? localStorage.getItem('language') :'SIN'  ;
        return (
            <div className='latestProject'>
                <Container >
                    <Row>
                        <Col sm={12} md={4} lg={4}>
                            <Card className='projectCard'>
                                <YouTube videoId={getYouTubeID1('https://www.youtube.com/watch?v=vCX31DydFLA')}
                                         opts={opts} onReady={this.onReady}/>
                                <div className='projectDetails'>
                                    <h4>Edulab - How to login to Student Account (ශිෂ්‍යයෙකුට ගිණුමට පිවිසීම)</h4>
                                    {/*<p style={{color: 'black'}}>Register and contact edulab team for create own web*/}
                                    {/*    portal.</p>*/}
                                </div>
                            </Card>
                        </Col>
                        <Col sm={12} md={4} lg={4}>
                            <Card className='projectCard'>
                                <YouTube videoId={getYouTubeID1('https://www.youtube.com/watch?v=ga5ugFtj8YU')}
                                         opts={opts} onReady={this.onReady}/>
                                <div className='projectDetails'>
                                    <h4>Edulab - How to Create Student Account (ශිෂ්‍ය ගිණුමක් සෑදීම)</h4>
                                    {/*<p style={{color: 'black'}}>Register and contact edulab team for create own web*/}
                                    {/*    portal.</p>*/}
                                </div>
                            </Card>
                        </Col>
                        <Col sm={12} md={4} lg={4}>
                            <Card className='projectCard'>
                                <YouTube videoId={getYouTubeID1('https://www.youtube.com/watch?v=Xk3iXTZ8IMk')}
                                         opts={opts} onReady={this.onReady}/>
                                <div className='projectDetails'>
                                    <h4>Edulab - How to Purchase a Class (පන්ති/ පාඨමාලා ගාස්තු ගෙවා ඇතුලත් වීම)</h4>
                                    {/*<p style={{color: 'black'}}>Register and contact edulab team for create own web*/}
                                    {/*    portal.</p>*/}
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default LatestProject;